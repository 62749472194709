import { useParams } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import Loader from "../Common/Loader";
import Error from "../Common/Error";
import UserEditForm from "./UserEditForm";

export default function UserEdit() {
  const { id } = useParams();
  const { loading, data: reqData, error } = useRequest(`/api/users/${id}`);
  const user = reqData?.data;
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return <UserEditForm user={user} />;
}
