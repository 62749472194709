import Api from '../libs/axios';

const getAll = async (search, page) => {
    return Api.get('/api/users?page='+page+'&search='+search);
}
const approveUser = async (userId) => {
    return Api.post('/api/users/change-status/'+userId, {status: 'approved', userId});
}
const rejectUser = async (userId) => {
    return Api.post('/api/users/change-status/'+userId, {status: 'rejected', userId});
}
const pendingUser = async (userId) => {
    return Api.post('/api/users/change-status/'+userId, {status: 'pending', userId});
}

const show = async (id) => {
    return Api.get('/api/users/'+id);
}

const create = async (params) => {
    return Api.post('/api/users', params);
}

const update = async (id,params) => {
    return Api.put('/api/users/'+id, params);
}

const remove = async (id) => {
    return Api.delete('/api/users/'+id);
}

const userService = {
    getAll,
    show,
    create,
    update,
    remove,
    approveUser,
    rejectUser,
    pendingUser
}
export default userService;