export default function Error({ error }) {
    console.log(error);
  const { response } = error;
  const msg = response.data?.error ?? response.data?.message;

  return (
    <div className="m-5 alert alert-danger" role="alert">
      {msg}
    </div>
  );
}
