import { createContext, useContext, useEffect, useState } from "react";
import authService from "../../services/authService";

export const AuthContext = createContext({
    user: null,
    login: ()=> {},
    register: ()=> {},
    logout: ()=> {},
    loading: false,
    firstLoading: true,
    errors: []
});
export default function AuthProvider({children, handleUserAuthenticate}) {
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);

    const userAuthenticate = ({user, roles}) => {
        setUser(user);
        setRoles(roles);
        handleUserAuthenticate(user);
    }

    const login = (email, password) => {
        setLoading(true);
        setErrors([]);

        return authService.login({email, password})
            .then(authData => userAuthenticate(authData))
            .catch((apiError)=> {
                setErrors([apiError.response.data.message])
                throw apiError;
            })
            .finally(() => setLoading(false))
    }

    const register = (data) => {
        setLoading(true);
        setErrors([]);

        authService.register(data)
            .then(authData => userAuthenticate(authData))
            .catch((errors)=> setErrors(errors))
            .finally(() => setLoading(false))
    }

    const logout = () => {
        setLoading(true);
        setErrors([]);

        authService.logout()
            .then(() => setUser(null))
            .catch((errors)=> setErrors(errors))
            .finally(() => setLoading(false))
    }

    const checkUser = () => {
        setFirstLoading(true);
        setErrors([]);

        authService.checkUser()
            .then((authData) => userAuthenticate(authData))
                .catch((errors)=> {setErrors(errors.data)})
                .finally(() => setFirstLoading(false))
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            checkUser()
        } else {
            setFirstLoading(false)
        }
    },[]);


    // TODO: use memo
    const auth = {
        user,
        roles,
        login,
        register,
        logout,
        errors,
        loading,
        firstLoading
    }

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};