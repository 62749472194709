import Api from '../libs/axios';

const getAll = async (page) => {
    return Api.get('/api/bookings?page='+page);
}

const show = async (id) => {
    return Api.get('/api/bookings/'+id);
}

const create = async (params) => {
    return Api.post(`/api/event-vendors/${params.eventVendorId}/book`, params);
}

const remove = async (id) => {
    return Api.delete('/api/bookings/'+id);
}

const bookingService = {
    getAll,
    show,
    create,
    remove
}
export default bookingService;