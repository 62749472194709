import axios from 'axios'
import toast from 'react-hot-toast';

const errorHandler = (error) => {
    const msg = error.response.data?.error ?? error.response.data?.message;
    if (msg) {
        toast.error(msg);   
    }
    return Promise.reject({ ...error })
}

const responseHandler = (response) => {
    const msg = response.data?.message;
    if (msg && ['post','put', 'delete'].indexOf(response.config.method.toLowerCase()) !== -1) {
        toast.success(msg);   
    }
    return response;
}

const requestInterceptor = (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
}

const Api = axios.create();

Api.defaults.baseURL = process.env.REACT_APP_API_URL;

Api.interceptors.request.use((config) => requestInterceptor(config));

Api.interceptors.response.use(resp => responseHandler(resp), error => errorHandler(error));

export default Api;