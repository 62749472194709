import member from './member';
import user from './user';
import dashboard from './dashboard';
import booking from './booking';
import { combineReducers } from '@reduxjs/toolkit';
export default combineReducers({
    member,
    user,
    dashboard,
    booking
});