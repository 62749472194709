import { useEffect, useState } from "react";
import { BsChevronLeft, BsSearch } from "react-icons/bs";
import { FaAddressBook } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { RiContactsLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import eventService from "../../services/dashboardService";
import SecondaryHeader from "../Common/SecondaryHeader";
import GoogleMap from "../Common/GoogleMap";
import VendorCard from "./VendorCard";

const EventView = () => {
  const { id } = useParams();
  const [eventVendors, setEventvendors] = useState([]);
  const events = useSelector((state) => state.event.data);
  const apiGoogleKey = 'AIzaSyBdpM4V_YVMHJh-YcrbxYQrkrQGvS0JOl0';

  // console.log(events);
  const event = events.filter((e) => e.id === parseInt(id))[0];
  //console.log(event);

  useEffect(() => {
    eventService.getVendors(id).then((res) => {
      setEventvendors(res.data.data);
    });
  }, []);

  if (events.length === 0) {
    return "";
  }

  return (
    <>
      <SecondaryHeader
        leftChildren={
          <>
            <li className="nav-item">
              <Link to="/" className="nav-link py-0">
                {" "}
                <BsChevronLeft />{" "}
              </Link>
            </li>
            <li className="nav-item">
              <span className="navbar-text">
                <span className="h6">
                  {event.name} | {event.category.name}
                </span>
              </span>
            </li>
          </>
        }
        rightChildren={
          <>
            <li className="nav-item">
              <span className="navbar-text">
                {eventVendors.length} Labs are available
              </span>
            </li>
          </>
        }
      />

      <div className="container">
        <div className="row g-2">
          <h5 className="">Search for Lab vendors</h5>
          <div className="col-6">
            <div className="form-floating mb-3">
              <input
                type="text"
                className={"form-control "}
                id="searchFromVendorName"
                placeholder="Vendor Name..."
              />
              <label htmlFor="searchFromVendorName">
                {" "}
                <RiContactsLine /> Search by name...
              </label>
            </div>
          </div>
          <div className="col-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className={"form-control "}
                id="searchFromVendorAddress"
                placeholder="Vendor Name..."
              />
              <label htmlFor="searchFromVendorAddress">
                {" "}
                <FaAddressBook /> Search by address...
              </label>
            </div>
          </div>
          <div className="col-2">
            <div className="form-floating mb-3">
              <input
                type="text"
                className={"form-control "}
                id="searchFromVendorPincode"
                placeholder="Vendor Name..."
              />
              <label htmlFor="searchFromVendorPincode">
                {" "}
                <GrLocation /> Search by pincode...
              </label>
            </div>
          </div>
        </div>

        <div className="row g-2">
            <div className="col-md-6"> 
                {eventVendors.map(vendor => {
                    return (<VendorCard vendor={vendor} key={vendor.id} />)
                })}
            </div>
            <div className="col-md-6">
            </div>
        </div>
      </div>
    </>
  );
};

export default EventView;
