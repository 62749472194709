import { useDispatch } from 'react-redux';
import './App.css';
import AppLayout from './components/AppLayout/AppLayout';
import AuthProvider from './components/Auth/auth';
import { fetchDashboard } from "./redux/reducers/dashboard";
import {fetchMembers} from './redux/reducers/member';

function App() {
  const dispatch = useDispatch();
  return (
    <>
      <AuthProvider handleUserAuthenticate={ user => {
        dispatch(fetchDashboard());
        // dispatch(fetchMembers());
      }}>
        <AppLayout className="bg-white min-h-full" style={{backgroundColor: '#e9ecef'}} >
        </AppLayout>
      </AuthProvider>
    </>
  );
}

export default App;
