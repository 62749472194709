import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import userSchema from "./userSchema";
import { createUser } from "../../redux/reducers/user";
import useRequestError from '../../hooks/useRequestError';
import { Button, Card, Label, Select, TextInput } from "flowbite-react";

export default function UserCreate() {
    const {isLoading, errors: requestErrors } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors
  } = useForm({ resolver: yupResolver(userSchema) });

  let navigate = useNavigate();

  useRequestError(requestErrors, setError);

  const onSubmit = async (formData) => {
    clearErrors();
    await dispatch(createUser(formData))
    navigate("/users");
  };
  return (
    <div className="grid grid-cols-3 gap-3">
  <div className="mt-5 ml-5">

    <Card>
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
    {/* <div>
    <div className="mb-2 block">
      <Label
        htmlFor="name"
        value="Store Name"
      />
    </div>
    <TextInput
      id="name"
      type="text"
      color={errors.name ? "failure" : ''}
      {...register("name")}
      helperText={errors.name && errors.name.message}
    />
  </div> */}
  <div>
    <div className="mb-2 block">
      <Label
        htmlFor="username"
        value="Username"
      />
    </div>
    <TextInput
      id="username"
      type="text"
      color={errors.username ? "failure" : ''}
      {...register("username")}
      helperText={errors.username && errors.username.message}
    />
  </div>
  <div>
    <div className="mb-2 block">
      <Label
        htmlFor="accessToken"
        value="Acess Token"
      />
    </div>
    <TextInput
      id="accessToken"
      type="text"
      color={errors.accessToken ? "failure" : ''}
      {...register("accessToken")}
      helperText={errors.accessToken && errors.accessToken.message}
    />
  </div>
  <div>
    <div className="mb-2 block">
      <Label
        htmlFor="url"
        value="Data source url"
      />
    </div>
    <TextInput
      id="url"
      type="text"
      color={errors.url ? "failure" : ''}
      {...register("url")}
      helperText={errors.url && errors.url.message}
    />
  </div>
  <div>
    <div className="mb-2 block">
      <Label
        htmlFor="frequency"
        value="Frequency"
      />
    </div>
    <Select
      id="frequency"
      type="text"
      color={errors.frequency ? "failure" : ''}
      {...register("frequency")}
      helperText={errors.frequency && errors.frequency.message}
    >
        <option value={'daily'}>
      Daily
    </option>
    <option value={'twiceDaily'}>
    Twice Daily
    </option>
        </Select>
  </div>
  <Button type="submit">
    Create
  </Button>
</form>
</Card>
</div>
  <div></div>
</div>
  );
}