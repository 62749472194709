import Api from '../libs/axios';

const getAll = async (page) => {
    return Api.get('/api/members?page='+page);
}

const show = async (id) => {
    return Api.get('/api/members/'+id);
}

const create = async (params) => {
    return Api.post('/api/members', params);
}

const update = async (id,params) => {
    return Api.put('/api/members/'+id, params);
}

const remove = async (id) => {
    return Api.delete('/api/members/'+id);
}

const memberService = {
    getAll,
    show,
    create,
    update,
    remove
}
export default memberService;