import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../Auth/auth";
import Home from '../Home/Home';
import Login from "../Login/Login";
import VendorLogin from "../Vendor/Login/Login";
import Register from "../Register/Register";
import Dashboard from "../Dashboard/Dasnboard";
import User from "../User/User";
import MemberCreate from "../Member/MemberCreate";
import MemberView from "../Member/MemberView";
import EventView from "../Event/EventView";
import MemberEdit from "../Member/MemberEdit";
import UserEdit from "../User/UserEdit";
import Loader from "../Common/Loader";
import BookingCreate from "../Booking/BookingCreate";
import Booking from "../Booking/Booking";
import Superapp from "../Superapp/Superapp";
import UserCreate from "../User/UserCreate";

function PrivateRoute() {
    const auth = useAuth();
    const location = useLocation();
    if (auth.firstLoading) {
        return <Outlet />;
    }
    if (!auth.user && !["/login", "register"].includes(location.pathname)) {
        return <Navigate to="/login" ></Navigate>
    }
    return <Outlet />;
}

function GuestRoute() {
    const auth = useAuth();
    if (auth.user) {
        return <Navigate to="/" ></Navigate>
    }
    return  <Outlet />;
}

export default function MainArea({children}) {
    const auth = useAuth();
    if (auth.firstLoading === true) {
        return (
            <Loader/>
        );
    }
    return (
        <main>
            <Routes>
                <Route element={<GuestRoute />}>
                    <Route path="/login" element={<Login />}  />
                    <Route path="vendor/login" element={<VendorLogin />}  />
                    {/* <Route path="/register" element={<Register />}  /> */}
                </Route>
                <Route  element={<PrivateRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="events/:id" element={<EventView />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="users" element={<User />} />
                    <Route path="add-user" element={<UserCreate />} />
                    <Route path="members/create" element={<MemberCreate />} />
                    <Route path="users/:id/view" element={<MemberView />} />
                    <Route path="users/:id/edit" element={<UserEdit />} />
                    <Route path="logout" render={()=> auth.logout()} />
                </Route>
            </Routes>
            {children}
        </main>
    );
}