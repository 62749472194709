import { Link, useResolvedPath, useMatch } from "react-router-dom";
import { useAuth } from "../Auth/auth";
import { Navbar } from "flowbite-react";
import { MdAddBusiness } from 'react-icons/md';

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  const activeClasses =
    "block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white";
  const inActiveClasses =
    "block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
  return (
    <Link
      style={{ textDecoration: match ? "no-underline" : "none" }}
      to={to}
      {...props}
      className={match ? activeClasses : inActiveClasses}
    >
      {children}
    </Link>
  );
}

function PrivateLink({ role, ...props }) {
  const auth = useAuth();
  return (
    auth.user !== null &&
    (auth.roles?.includes(role) || true) && <CustomLink {...props}></CustomLink>
  );
}

function GuestLink({ ...props }) {
  const auth = useAuth();
  return auth.user === null && <CustomLink {...props}></CustomLink>;
}

export default function Navs() {
  const auth = useAuth();

  return (
    <Navbar.Collapse>

      <PrivateLink to="/">Home</PrivateLink>

      <PrivateLink to="/users">Shops</PrivateLink>
      <PrivateLink to="/add-user"> <div><MdAddBusiness className="float-left text-lg mr-2" /> Add Shop</div> </PrivateLink>

      {/* 
                <PrivateLink to="/dashboard" >Dashboard</PrivateLink>
             */}

      <GuestLink to="/login">Login</GuestLink>

      {/* <GuestLink to="/register">Register</GuestLink> */}

      {/* 
              <PrivateLink to="/dashboard">Dashboard</PrivateLink>
             */}

      {auth.user && (
        <button
          onClick={() => auth.logout()}
          className="nav-link btn "
          type="button"
        >
          Logout
        </button>
      )}
    </Navbar.Collapse>
  );
}
