import Api from '../libs/axios';

const login = async (params) => {
    return Api.post('/api/login', params)
        .then(({data:{token, user, roles}}) => {
            localStorage.setItem('token', token)
            return {user, roles};
        });
}

const register = async (params) => {
    return Api.post('/api/register', params)
        .then(({data:{data : {user, token, roles}}}) =>  {
            //console.log(user, token)
            localStorage.setItem('token', token)
            return {user, roles};
        });
}

const checkUser = async () => {
    return Api.get('/api/user')
    .then(({data : {user, roles}}) => {
        return {user, roles};
    });
}

const checkServer = async (params) => {
    return Api.get('/api/hello');
}

const logout = async () => {
    return Api.post('/api/logout')
        .then(() => localStorage.removeItem('token'));
}

const authService = {
    login,
    register,
    checkUser,
    checkServer,
    logout
}
export default authService;