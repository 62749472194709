import { Button, Modal } from "flowbite-react";
import userService from "./../../services/userService";

export default function RejectModal({ show, userId, onRejectHandler, onCloseHandler }) {

    const onReject = () => {
        userService.rejectUser(userId)
        .then((resp) => {
            onRejectHandler()
        })
    }
  return (
    <Modal show={show} size="md" popup={true} onClose={onCloseHandler}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to reject this user?
          </h3>
          <div className="flex justify-center gap-4">
            <Button onClick={onReject}>Yes, I'm sure</Button>
            <Button color="failure" onClick={onCloseHandler}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
