import {object, string, date, number } from "yup";
const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const userSchema = object({
    // name: string().required(),
    username: string().required(),
    accessToken: string().required(),
    url: string().required().matches(re, 'URL is not valid'),
    frequency: string().required(),
});

export default userSchema;