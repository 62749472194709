import Navs from "./Navs";
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

export default function Header() {
    return (
	<Navbar fluid={true} rounded={true} className="border-b">
		<Link to="/" className="flex items-center">
        <img src="https://flowbite.com/docs/images/logo.svg" className="h-6 mr-3 sm:h-9" alt="Flowbite Logo" />
        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Dashboard</span>
    </Link>
  <Navbar.Toggle />
		<Navs></Navs>
	</Navbar>
	);
}
