import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdBloodtype, MdOutlineFilterList } from "react-icons/md";
import { BiGroup } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { Card } from "flowbite-react";

export default function Home() {
  const [search, setSearch] = useState(null);
  const dashboard = useSelector((state) => state.dashboard.data);
  const handleOnSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div className="m-5">
      <div className="grid grid-cols-4 gap-4">
        <div>
          <Card href="#">
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {dashboard && dashboard.users}
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Total active shops
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
}
