import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/auth";
import "./Login.css";

export default function Login() {
  const { register,clearErrors, setError, formState: { errors }, handleSubmit } = useForm();
  const {login} = useAuth();
  let navigate = useNavigate();
  
  const onSubmit = async (data) => {
    clearErrors('password');
    clearErrors('email');
    await login(data.email, data.password)
    .then(()=> {
        navigate('/vendor/booking',{ replace: true });
    })
    .catch((apiError)=> {
        setError('password', { type: 'custom', message: apiError.response.data.message });
        setError('email', { type: 'custom', message:null });
    })
  };

  const onSubmitError = async (errors, e) => console.log(errors, e);

    return (
        <div style={{ alignItems: "center" }} className="text-center">
            <main className="form-signin">
                <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
                    <h1 className="h3 mb-3 fw-normal">Vendor sign in</h1>

                    <div className="form-floating has-validation">
                        <input
                            type="email"
                            className={"form-control "+ (errors.email && 'is-invalid') }
                            id="floatingInput"
                            placeholder="name@example.com"
                            {...register('email', {required: true})}
                        />
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating has-validation">
                        <input
                            type="password"
                            className={"form-control "+ (errors.password ? 'is-invalid' : '') }
                            id="floatingPassword"
                            placeholder="Password"
                            {...register('password', {required: true, minLength:4})}
                        />
                        <label htmlFor="floatingPassword">Password</label>
                        {errors.password && <div className="invalid-feedback">
                            {errors.password.message}
                        </div>}
                    </div>

                    {/* <div className="checkbox mb-3">
                        <label>
                            <input type="checkbox" value="remember-me" />{" "}
                            Remember me
                        </label>
                    </div> */}
                    <button className="w-100 btn btn-lg btn-dark" type="submit">
                        Sign in
                    </button>
                    <p className="mt-3 mb-3 text-muted">
                        © {new Date().getFullYear()}
                    </p>
                </form>
            </main>
        </div>
    );
}
