import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/auth";
import "./Register.css";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from "axios";

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required().test('checkDuplicateEmail', 'This email is already registered', (val) => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/check-email/${val}`)
            .then((resp) => {
                resolve(!resp.data.isExists)
            }).catch(() => {
                // note exists
                resolve(true)
            })
        })
    }),
    password: yup.string().min(6).required(),
  }).required();

export default function Register() {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({resolver: yupResolver(schema)});
    const auth = useAuth();
    let navigate = useNavigate();

    const onSubmit = async ({ name, email, password }) => {
        await auth.register({
            name,
            email,
            password,
        });
        navigate("/", { replace: true });
    };

    return (
        <div style={{ alignItems: "center" }} className="text-center">
            <form onSubmit={handleSubmit(onSubmit)} className="form-signup">
                <h1 className="h3 mb-3 fw-normal">Please sign up here.</h1>
                <div className="form-floating mb-3">
                    <input
                        type="text"
                        className={"form-control "+ (errors.name && 'is-invalid') }
                        id="registerFormName"
                        placeholder="..."
                        {...register('name')}
                    />
                    <label htmlFor="registerFormName">Name</label>
                    {errors.name && <div className="invalid-feedback">
                            {errors.name.message}
                        </div>}
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        className={"form-control "+ (errors.email && 'is-invalid') }
                        id="registerFormEmail"
                        placeholder="name@example.com"
                        {...register('email')}
                    />
                    <label htmlFor="registerFormEmail">Email address</label>
                    {errors.email && <div className="invalid-feedback">
                            {errors.email.message}
                        </div>}
                </div>
                <div className="form-floating">
                    <input
                        type="password"
                        className={"form-control "+ (errors.password && 'is-invalid') }
                        id="registerFormPassword"
                        placeholder="Password"
                        {...register('password')}
                    />
                    <label htmlFor="registerFormPassword">Password</label>
                    {errors.password && <div className="invalid-feedback">
                            {errors.password.message}
                        </div>}
                </div>
                <div className="checkbox mt-3">
                        <label>
                            <input type="checkbox" value="remember-me" />{" "}
                            Terms & Conditions
                        </label>
                    </div>
                <button className="w-100 mt-3 btn btn-lg btn-dark" type="submit">
                        Sign up
                    </button>
                    <p className="mt-3 mb-3 text-muted">
                        © {new Date().getFullYear()}
                    </p>
            </form>
        </div>
    );
}
