export default function SecondaryHeader({ leftChildren, rightChildren }) {
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white border-bottom  mb-4 shadow-sm">
      <div className="container-fluid">
        <ul className="nav my-2">
          &nbsp;
            {leftChildren}
        </ul>
        <ul className="nav justify-content-end">
            {rightChildren}
        </ul>
      </div>
    </nav>
  );
}
