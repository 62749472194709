import bookingService from "../../services/bookingService";

// ActionTypes 

const FETCH_ALL = 'bookings/FETCH_ALL';
const PAGE_CHANGE = 'bookings/PAGE_CHANGE';
// const CREATE = 'bookings/CREATE';
// const UPDATE = 'bookings/UPDATE';
// const REMOVE = 'bookings/REMOVE';
const REQUEST_STATUS = 'bookings/REQUEST_STATUS';
const REQUEST_ERRORS = 'bookings/REQUEST_ERRORS';

const initialState = {
    isLoading: false,
    errors:[],
    data:[],
    page: 1, 
    meta: {
        perPage: 15,
        currentPage: 1,
        lastPage: 1,
        total: 0,
    }

}

// action creators
const setLoading = () => ({
    type: REQUEST_STATUS,
    payload: true
});
// action creators
const stopLoading = () => ({
    type: REQUEST_STATUS,
    payload: false
});

const setErrors = (errors) => ({
  type: REQUEST_ERRORS,
  payload: errors
});

const clearErrors = () => ({
  type: REQUEST_ERRORS,
  payload: []
});

const changePage = (page) => ({
  type: PAGE_CHANGE,
  payload: page
})

// thunk action creators

// const thunkActionProducer = (thunkAction) => {
//   return () => {
//     try {
//       setLoading()
//       thunkAction();
//     } catch (error) {
//       console.log('fetchNewsSaga error',error);
//     } finally {
//       stopLoading();
//     }
//   }
// }

export const fetchBookings = (page = 1) => async (dispatch, getState) => {
    try {
      dispatch(clearErrors())
      dispatch(setLoading())
      dispatch(changePage(page))
      const {data} = await bookingService.getAll(page);
      dispatch({type:FETCH_ALL, payload:data})
    } catch (error) {
      dispatch(setErrors(error));
    } finally {
      dispatch(stopLoading());
    }
}

export const createBooking = (data) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    dispatch(setLoading());
    await bookingService.create(data);
    dispatch(fetchBookings())
  } catch (error) {
    dispatch(setErrors(error?.response?.data?.errors ?? []));
    throw error;
  } finally {
    dispatch(stopLoading());
  }
}

export const updateBooking = (id, data) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    dispatch(setLoading());
    await bookingService.update(id, data);
    dispatch(fetchBookings())
  } catch (error) {
    dispatch(setErrors(error?.response?.data?.errors ?? []));
    throw error;
  } finally {
    dispatch(stopLoading());
  }
}

export const deleteBooking = (id) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    dispatch(setLoading());
    await bookingService.remove(id);
    dispatch(fetchBookings())
  } catch (error) {
    dispatch(setErrors(error?.response?.data?.errors ?? []));
    throw error;
  } finally {
    dispatch(stopLoading());
  }
}


const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL:
            return {
                ...state, 
                data: action.payload.data,
                meta: { 
                    perPage: action.payload.meta.per_page,
                    currentPage: action.payload.meta.current_page,
                    lastPage: action.payload.meta.last_page,
                    total: action.payload.meta.total,
                }
            };
        case REQUEST_STATUS:
          return {
            ...state,
            isLoading: action.payload
          }
        
        case REQUEST_ERRORS:
          return {
            ...state,
            errors: action.payload
          }
        
        case PAGE_CHANGE:
          return {
            ...state,
            page: action.payload
          }
        default:
            return state;
    }
}

export default reducer;