import { useEffect, useState } from "react";
import Api from "../libs/axios";


export default function useRequest(uri) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const resp = await Api.get(uri);
                const data = resp?.data;
                
                setData(data);
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [uri]);

    return {
        loading,
        data,
        error
    };
}