import { FaPen } from "react-icons/fa";
import { BsChevronLeft } from "react-icons/bs";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import Error from "../Common/Error";
import Loader from "../Common/Loader";
import SecondaryHeader from "../Common/SecondaryHeader";
import useRequest from "../../hooks/useRequest";
import DeleteModel from "../Common/DeleteModel";
import { useDispatch } from "react-redux";
import { deleteMember } from "../../redux/reducers/member";

export default function MemberView() {
  const { id } = useParams();
  const { loading, data: reqData, error } = useRequest(`/api/members/${id}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const member = reqData?.data;

  const handleDelete = async () => {
    await dispatch(deleteMember(id));

    navigate("/members");
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      <SecondaryHeader
        leftChildren={
          <>
            <li className="nav-item">
              <Link to="/members" className="nav-link py-0">
                {" "}
                <BsChevronLeft />{" "}
              </Link>
            </li>
            <li className="nav-item">
              <span className="navbar-text">
                Name: <span className="h6">{member.name}</span>
              </span>
              {/* <p >/</p>    Name: <h5 className="mb-0">{member.name}</h5> */}
            </li>
          </>
        }
        rightChildren={
          <>
            <li className="nav-item mx-2">
              <DeleteModel
                btnClass={"btn btn-sm btn-outline-danger"}
                onDelete={handleDelete}
              />
              {/* <button type="button" className="btn btn-sm btn-outline-danger"> <FaTimes /> Delete</button> */}
            </li>
            <li className="nav-item">
              <Link
                to={`/members/${member.id}/edit`}
                className="btn btn-sm btn-outline-dark"
              >
                <FaPen /> Edit Member
              </Link>
            </li>
          </>
        }
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text">{member.fullAddress}</p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Email: {member.email}</li>
                <li className="list-group-item">Phone: {member.phone}</li>
                <li className="list-group-item">Gender: {member.gender}</li>
                <li className="list-group-item">Age: {member.age}</li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title">Biospy</h5>
                <p className="card-text">
                  You should do following pathology test
                </p>
              </div>
            </div>
            <div className="list-group border border-5">
              {member.suggestedEvents.map((event) => {
                return (
                  <Link
                  to={`/bookings/create?eventId=${event.id}&memberId=${member.id}`}
                  className="list-group-item list-group-item-action"
                    aria-current="true"
                   
                  >
                    <div className="d-flex w-100 justify-content-between" key={event.id}>
                      <h5 className="mb-1">{event.name}</h5>
                      <small>{event.category.name}</small>
                    </div>
                    <p className="mb-1">
                      Some placeholder content in a paragraph.
                    </p>
                    <div className="d-flex w-100 justify-content-between align-items-end">
                      <small>
                        Age group{" "}
                        {`${event.category.min_age} - ${event.category.max_age}`}
                      </small>
                      <button className="btn btn-outline-dark btn-sm">
                        Book now <AiOutlineDoubleRight />{" "}
                      </button>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
