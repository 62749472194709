import dashboardService from "../../services/dashboardService";

// ActionTypes 

const FETCH_ALL = 'dashboards/FETCH_ALL';
const REQUEST_STATUS = 'dashboards/REQUEST_STATUS';
const REQUEST_ERRORS = 'dashboards/REQUEST_ERRORS';

const initialState = {
    data:[]
}

// action creators
const setLoading = () => ({
    type: REQUEST_STATUS,
    payload: true
});
// action creators
const stopLoading = () => ({
    type: REQUEST_STATUS,
    payload: false
});

const setErrors = (errors) => ({
  type: REQUEST_ERRORS,
  payload: errors
});

const clearErrors = () => ({
  type: REQUEST_ERRORS,
  payload: []
});

// thunk action creators

// const thunkActionProducer = (thunkAction) => {
//   return () => {
//     try {
//       setLoading()
//       thunkAction();
//     } catch (error) {
//       console.log('fetchNewsSaga error',error);
//     } finally {
//       stopLoading();
//     }
//   }
// }

export const fetchDashboard = () => async (dispatch, getState) => {
    try {
      dispatch(clearErrors())
      dispatch(setLoading())
      const data = await dashboardService.getAll();
      dispatch({type:FETCH_ALL, payload:data})
    } catch (error) {
      dispatch(setErrors(error));
    } finally {
      dispatch(stopLoading());
    }
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL:
            return {
                ...state, 
                data: action.payload.data
            };
        case REQUEST_STATUS:
          return {
            ...state,
            isLoading: action.payload
          }
        
        case REQUEST_ERRORS:
          return {
            ...state,
            errors: action.payload
          }
        
        default:
            return state;
    }
}

export default reducer;