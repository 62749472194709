import Api from '../libs/axios';

const getAll = async () => {
    return Api.get('/api/dashboard');
}


const dashboardService = {
    getAll
}
export default dashboardService;