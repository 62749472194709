
import { Table, Pagination, Tabs } from "flowbite-react";
import ApprovedUser from "./ApprovedUser";
import PendingUser from "./PendingUser";
import RejectedUser from "./RejectedUser";
// import {useSearchParams} from 'react-router-dom';

export default function User() {
  return (
    <>
      {/* <SecondaryHeader
        leftChildren={(
            <li className="nav-item">
                <h5 className="mb-0">Users</h5>
            </li>
        )}
        rightChildren={(
            <li className="nav-item">
            <Link to="/users/create" className="btn btn-sm btn-outline-dark">
            <FaPlus/> Add User
          </Link>
          </li>
        )}
      /> */}
      <div className="">
      <ApprovedUser />
        {/* <Tabs.Group aria-label="Full width tabs" style="fullWidth">
          <Tabs.Item title="Pending">
            <PendingUser />
          </Tabs.Item>
          <Tabs.Item title="Approved">
            <ApprovedUser />
          </Tabs.Item>
          <Tabs.Item title="Rejected">
            <RejectedUser />
          </Tabs.Item>
        </Tabs.Group> */}
      </div>
    </>
  );
}
