import {object, string, date, number } from "yup";

const memeberSchema = object({
    name: string().required(),
    email: string().email().required(),
    phone: string().required().min(10),
    birthday: date().max(new Date()).required(),
    gender: string().required(),
    height: number().min(0).required(),
    weight:number().min(0).required(),
    address: string().required(),
    pincode: string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
    city: string().required(),
    state: string().required(),
    country: string().required(),
});

export default memeberSchema;