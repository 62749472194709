import { BsCalendarCheck } from "react-icons/bs";
import { Link } from "react-router-dom";

const VendorCard = ({ vendor }) => {
  return (
    <div className="card mb-3">
      <div className="row g-0">
        <div className="col-md-4">
          <img
            src="https://via.placeholder.com/180x270"
            className="img-fluid rounded-start"
            alt="..."
          />
        </div>
        <div className="col-md-8">
          <div className="card-body d-flex flex-column ">
            <h5 className="card-title">{vendor.vendor.name}</h5>
            <p className="card-text">Price: 1500</p>
            <p className="card-text">Address: {vendor.vendor.address1}</p>
            <p className="card-text">
              Contact: {vendor.vendor.contact1} / {vendor.vendor.contact2}
            </p>
            <p className="card-text mt-auto">
              <small className="text-muted">Last updated 3 mins ago</small>
            </p>
            <Link
              className="btn btn-dark w-100"
              to={`/bookings/create?eventId=${vendor.event_id}&eventVendorId=${vendor.id}`}
            >
              Book Appointment <BsCalendarCheck />{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorCard;
