import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchUsers } from "../../redux/reducers/user";

import { Table, Pagination, Tabs, Button, TextInput } from "flowbite-react";
import RejectModal from "./RejectModal";
import PendingModal from "./PendingModal";
import { Link } from "react-router-dom";
import { FaPen } from "react-icons/fa";
// import {useSearchParams} from 'react-router-dom';

export default function ApprovedUser() {
  const thisStatus = "approved";
  const { data: users, meta } = useSelector(
    (state) => state.user.users
  );

  const dispatch = useDispatch();
  const [showPending, setShowPending] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [selectUserId, setSelectUserId] = useState(null);
  const [search, setSearch] = useState('');

  const onPendingClick = (userId) => {
    setShowPending(true);
    setSelectUserId(userId);
  };
  const onRejectClick = (userId) => {
    setShowReject(true);
    setSelectUserId(userId);
  };

  const handleOnSearch = (e) => {
    setSearch(e.target.value);
    
  };

  const handlePageChange = (page) => {
    dispatch(fetchUsers(search, page));
  };
  useEffect(() => {
    dispatch(fetchUsers(search)); // dispatch(fetchUsers(thisStatus, page.get('page')))
  }, [search]);

  return (
    <div className="">
      <div className="m-5">
      <TextInput
      
      type="text"
      sizing="sm"
      placeholder="Search....."
      value={search}
      onChange={handleOnSearch}
    />
      </div>  
      <Table>
        <Table.Head>
          <Table.HeadCell>#</Table.HeadCell>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Username</Table.HeadCell>
          <Table.HeadCell>Is Running</Table.HeadCell>
          <Table.HeadCell>Ran At</Table.HeadCell>
          <Table.HeadCell>Created</Table.HeadCell>
          <Table.HeadCell>Action</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {users.map((post) => {
            return (
              <Table.Row
                key={post.id}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.id}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.name}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.email}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.is_executing ? 'Yes' : 'No'}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.execute_at}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {post.created_at}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                <Link
                to={`/users/${post.id}/edit`}
                className="btn btn-sm btn-outline-dark"
              >
                <Button color="warning" size="xs">
                <FaPen />&nbsp; Edit
    </Button>
                
              </Link>
                  {/* <Button.Group>
                    <Button
                      onClick={() => onPendingClick(post.id)}
                      key={post.id + "p"}
                      color="warning"
                    >
                      Pending
                    </Button>
                    <Button
                      onClick={() => onRejectClick(post.id)}
                      key={post.id + "r"}
                      color="light"
                    >
                      Reject
                    </Button>
                  </Button.Group> */}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Pagination
        currentPage={meta.currentPage}
        onPageChange={handlePageChange}
        showIcons={true}
        totalPages={meta.lastPage}
      />
      <PendingModal
        show={showPending}
        userId={selectUserId}
        onPendingHandler={() => {
          handlePageChange(1);
          setShowPending(false);
          dispatch(fetchUsers("pending"));
        }}
        onCloseHandler={() => {
          setShowPending(false);
        }}
      />
      <RejectModal
        show={showReject}
        userId={selectUserId}
        onRejectHandler={() => {
          handlePageChange(1);
          setShowReject(false);
          dispatch(fetchUsers("rejected"));
        }}
        onCloseHandler={() => {
          setShowReject(false);
        }}
      />
    </div>
  );
}
