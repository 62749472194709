import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./MemberForm.css";
import { yupResolver } from "@hookform/resolvers/yup";
import memeberSchema from "./memberSchema";
import { createMember } from "../../redux/reducers/member";
import useRequestError from '../../hooks/useRequestError';

const MemberCreate = () => {
  const {isLoading, errors: requestErrors } = useSelector(state => state.member);
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors
  } = useForm({ resolver: yupResolver(memeberSchema) });

  let navigate = useNavigate();

  useRequestError(requestErrors, setError);

  const onSubmit = async (formData) => {
    clearErrors();
    await dispatch(createMember(formData))
    navigate("/members");
  };

  return (
    <div className="container-fluid">
      <div className="row g-3">
        <div className="col">
          <div style={{ alignItems: "center" }} className="text-center">
            <form onSubmit={handleSubmit(onSubmit)} className="form-member">
              <h1 className="h3 mb-3 fw-normal">Add Member</h1>
              <h5 className="h5 mb-3 fw-light pull-left">Basic details</h5>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={"form-control " + (errors.name && "is-invalid")}
                  id="memberFormName"
                  placeholder="..."
                  {...register("name")}
                />
                <label htmlFor="registerFormName">Name</label>
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </div>

              <div className="row g-3 ">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className={
                        "form-control " + (errors.email && "is-invalid")
                      }
                      id="registerFormEmail"
                      placeholder="name@example.com"
                      {...register("email")}
                    />
                    <label htmlFor="registerFormEmail">Email address</label>
                    {errors.email && (
                      <div className="invalid-feedback">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className={
                        "form-control " + (errors.phone && "is-invalid")
                      }
                      id="registerFormPhone"
                      placeholder="+91 90...."
                      {...register("phone")}
                    />
                    <label htmlFor="registerFormPhone">Phone</label>
                    {errors.phone && (
                      <div className="invalid-feedback">
                        {errors.phone.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="row g-3 ">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      step="0.01"
                      className={
                        "form-control " + (errors.height && "is-invalid")
                      }
                      id="memberFormHeight"
                      placeholder="00.00"
                      {...register("height")}
                    />
                    <label htmlFor="memberFormHeight">Height</label>
                    {errors.height && (
                      <div className="invalid-feedback">
                        {errors.height.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      step="0.01"
                      className={
                        "form-control " + (errors.weight && "is-invalid")
                      }
                      id="memberFormWeight"
                      placeholder="00.00"
                      {...register("weight")}
                    />
                    <label htmlFor="memberFormWeight">Weight</label>
                    {errors.weight && (
                      <div className="invalid-feedback">
                        {errors.weight.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row g-3 ">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      className={
                        "form-control " + (errors.birthday && "is-invalid")
                      }
                      placeholder="yyyy-mm-dd"
                      min="1957-01-01" max="2030-12-31"
                      id="memberFormBirthday"
                      {...register("birthday")}
                    />
                    <label htmlFor="memberFormBirthday">Height</label>
                    {errors.birthday && (
                      <div className="invalid-feedback">
                        {errors.birthday.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <select className={
                        "form-control " + (errors.gender && "is-invalid")
                      }
                      id="memberFormGender"
                      {...register("gender")}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <label htmlFor="memberFormGender">Gender</label>
                    {errors.gender && (
                      <div className="invalid-feedback">
                        {errors.gender.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <h5 className="h5 mb-3 fw-light pull-left">Address details</h5>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={"form-control " + (errors.address && "is-invalid")}
                  id="memberFormAddress"
                  placeholder="..."
                  {...register("address")}
                />
                <label htmlFor="memberFormAddress">Address</label>
                {errors.address && (
                  <div className="invalid-feedback">{errors.address.message}</div>
                )}
              </div>

              <div className="row g-3 ">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      placeholder="123456"
                      className={
                        "form-control " + (errors.pincode && "is-invalid")
                      }
                      id="memberFormPincode"
                      {...register("pincode")}
                    />
                    <label htmlFor="memberFormPincode">Pincode</label>
                    {errors.pincode && (
                      <div className="invalid-feedback">
                        {errors.pincode.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      placeholder="City"
                      className={
                        "form-control " + (errors.city && "is-invalid")
                      }
                      id="memberFormCity"
                      {...register("city")}
                    />
                    <label htmlFor="memberFormCity">City</label>
                    {errors.city && (
                      <div className="invalid-feedback">
                        {errors.city.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <div className="row g-3 ">
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      placeholder="State"
                      className={
                        "form-control " + (errors.state && "is-invalid")
                      }
                      id="memberFormState"
                      {...register("state")}
                    />
                    <label htmlFor="memberFormState">State</label>
                    {errors.state && (
                      <div className="invalid-feedback">
                        {errors.state.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      placeholder="Country"
                      className={
                        "form-control " + (errors.country && "is-invalid")
                      }
                      id="memberFormCountry"
                      {...register("country")}
                    />
                    <label htmlFor="memberFormCountry">Country</label>
                    {errors.country && (
                      <div className="invalid-feedback">
                        {errors.country.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>


              <button className="w-100 mt-3 btn btn-lg btn-dark" type="submit" disabled={isLoading}>
                {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                Save
              </button>

              <button 
                className="w-100 mt-3 btn btn-lg btn btn-outline-secondary"
                type="button"
                onClick={() => {
                  navigate("/members");
                }}
                >
                Cancel
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MemberCreate;