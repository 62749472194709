import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

Modal.setAppElement('#root');
export default function DeleteModel({onDelete, btnClass}) {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

    return (
        <>
        <button onClick={openModal} type="button" className={btnClass}> <FaTimes /> Delete</button>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        
      >
        <h2>Hello</h2>
        <button onClick={closeModal}>close</button>
        <button onClick={onDelete}>Delete</button>
        <div>I am a modal</div>
      </Modal>
      </>
    );
}